import { useState, useEffect, memo, useRef, Fragment } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import UILIB from "components";
import Axios from 'classes/axios';
import { permissionDecider, permissionsObject } from 'classes/permissions';
import Modules from './Modules';


const PortalHubTemplate = memo((props) => {

  const location = useLocation();
  const history = useHistory();
  const account = useSelector((state) => state.account);
  const permissions = useSelector((state) => state.permissions);
  const [isAdmin, setIsAdmin] = useState(undefined);
  const permissionsData = useRef([{ name: 'portalHub', hubName: 'Portal Hub', routeName: location.pathname }]);
  const localPermissions = useRef({});
  const defaultSelected = { selected: false, customerName: null, customerId: null };
  const [selectedCustomer, setSelectedCustomer] = useState(defaultSelected);
  const [currentTab, setCurrentTab] = useState(0);
  const [links, setLinks]  = useState(null);

  function actionChangeTab (newId) {
    setSelectedCustomer(defaultSelected);
    setCurrentTab(newId);
  }

  useEffect(() => {
    const actionAsync = async () => {
      await permissionDecider(permissions, account, permissionsData.current, localPermissions.current);
      setIsAdmin(permissionsObject('portalHubAdminView', localPermissions.current.permissions, permissions, location.pathname).entityEnable);
      await props.permissions.generalAccess();
      const linkResult = await Axios.get(`/entities/workflow/portals/actionGetLinks`);
      console.log(linkResult)
      setLinks(linkResult.data.result);
    };
    actionAsync();
  }, []);

  const tabs = [
    { label: "Utilities", value: 0, disabled: !links },
    { label: "Customers", value: 1 },
    { label: "Users", value: 2, disabled: !links },
    { label: "Registration", value: 3 },
    { label: "Requests", value: 4 },
    { label: "Migrations", value: 5 }];

  return <Fragment>
    <div className="flex-container row center width-100 pad-l5 pad-r5">
      <div className="flex-container column width-100 mar-t10">
        <UILIB.Paper className='flex-item row width-100 mar-b5'>
          <div className='flex-item start width-100 mar-b20'><h3>Portal Hub</h3></div>
          <div className='flex-item start flex-grow-1'>
            <UILIB.Button value="Back" className="grey" onClick={() => history.push('/')} />
          </div>
        </UILIB.Paper>
      </div>
      <div className="flex-container row width-100">
        {(!isAdmin && isAdmin !== undefined) && <Modules.PortalUtilities links={links}/>}
        {(isAdmin && isAdmin !== undefined) && 
        <Fragment>
          <UILIB.SubNav onClick={(newId) => actionChangeTab(newId)} tabs={tabs} currentTab={currentTab} outerClasses="mar-t15 mar-b15" />
          {currentTab === 0 && <Modules.PortalUtilities links={links}/>}
          {currentTab === 1 && <Modules.CustomerLookup setCurrentTab={setCurrentTab} setSelectedCustomer={setSelectedCustomer} />}
          {currentTab === 2 && <Modules.UserLookup links={links} selectedCustomer={selectedCustomer} setSelectedCustomer={setSelectedCustomer} defaultSelected={defaultSelected} />}
          {currentTab === 3 && <Modules.RegistrationLookup/>}
          {currentTab === 4 && <Modules.RequestsLookup/>}
          {currentTab === 5 && <Modules.UserMigration links={links} selectedCustomer={selectedCustomer} setSelectedCustomer={setSelectedCustomer} defaultSelected={defaultSelected}/>}
          
        </Fragment>}
      </div>
    </div>
  </Fragment>
});

export default PortalHubTemplate;
