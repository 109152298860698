import { useState, useRef, Fragment } from "react";
import { useDispatch } from "react-redux";
import Axios from "classes/axios";
import UILIB from "components";
import { formatDateTime } from "classes/format";
import { getYesNo, actionOpenNotesDrawer, tableContainer, rowSelected, selectAllRows, checkRow } from '../functions'

export default function MIFExceptionsTable({ context }) {

  const dispatch = useDispatch();
  const entityData = useRef({});
  const defaultDialogue = { exceptionIds: [], count: 0, show: false };
  const [showDeleteDialogue, setShowDeleteDialogue] = useState(defaultDialogue);
  const [deleting, setDeleting] = useState(false);
  const [selectedRows, setSelectedRows] = useState({});

  const selectAllCheckboxColumn = 
    [{
      label: (
        <UILIB.Checkbox
          onChange={(event) => selectAllRows(event, entityData, setSelectedRows, 'equipmentId')}
          checked={entityData.current.result && entityData.current.result.length > 0 && entityData.current.result.every(row => selectedRows[row.equipmentId])}
        />
      ),
      value: "rowSelected",
      align: "left",
      type: 'number'
    }];

  const headerData = [
    ...selectAllCheckboxColumn,
    { label: "Customer Name", value: "customerName", type: "string", filtering: true, minWidth: 250, maxWidth: 250 },
    { label: "Serial Number", value: "SerialNumber", type: "string", filtering: true, minWidth: 120, maxWidth: 120 },
    { label: "Description", value: "description", type: "string", filtering: true, minWidth: 150, maxWidth: 150 },
    { label: "Exception Reason", value: "reason", type: "string", filtering: true, minWidth: 300, maxWidth: 300 },
    { label: "Creation Date", value: "createdDate", type: "date", filtering: true, minWidth: 150, maxWidth: 150 },
    { label: "Created By", value: "createdBy", type: "string", filtering: true, minWidth: 150, maxWidth: 150 },
    { label: "Modified Date", value: "modifiedDate", type: "date", filtering: true, minWidth: 150, maxWidth: 150 },
    { label: "Modified By", value: "modifiedBy", type: "string", filtering: true, minWidth: 150, maxWidth: 150 },
    { label: "Notes", value: "note", type: "string", filtering: false, minWidth: 60, maxWidth: 60, filterArray: getYesNo() },
  ];

  let orderBy = '';

  if(context.value === 1) {
    orderBy = 'impacted'
  }
  else if(context.value === 2) {
    orderBy = 'neverCommunicated'
  }

  const pageSelectedNumber = 50;
  const tablePageDefaults = { deleteEnabled: false, paging: { pages: [10, 20, 50, 100, 200], pageSelected: pageSelectedNumber, limit: pageSelectedNumber, offset: 0, orderBy: orderBy, orderDir: 'DESC' } };

  const [localRender, setLocalRender] = useState(false);
  const [remoteRender, setRemoteRender] = useState(false);

  const handleDeleteExceptionClicked = async () => {
    const selectedRowIds = Object.entries(selectedRows)
      .filter(([id, isSelected]) => isSelected)
      .map(([id]) => Number(id))
      .filter((id) => checkRow(id, entityData, 'equipmentId'));

      setShowDeleteDialogue({ count: selectedRowIds.length, show: true, exceptionIds: selectedRowIds });
  };

  const handleDelete = async (selectedRowIds) => {
    setDeleting(true);

    try {
      await Axios.delete(`/entities/mif_exceptions/mifExceptions/softDelete`, { data: { equipmentIds: selectedRowIds } });
    } catch (error) {
      console.error('Error deleting mif exception', error);
    }

    try {
      await Axios.delete(`/entities/mifNotes/softDelete`, { data: { equipmentIds: selectedRowIds } });
      entityData.current.result = entityData.current.result.filter(x => !selectedRowIds.map(m => Number(m)).includes(Number(x.equipmentId)));
      setShowDeleteDialogue(defaultDialogue);
      setLocalRender(!localRender);
      setSelectedRows({});
    } catch (error) {
      console.error('Error deleting mif note', error);
    }

    setDeleting(false);
  }

  async function constructTable(mifData) {
    const tableData = mifData.filter(md => md.isDeleted === false).map(row => {
      const b_hasNotes = Boolean(row.notes && row.notes.length);
      const fn_notes = actionOpenNotesDrawer.bind(null, 'ViewNotesDrawer', row, dispatch, true, { get: remoteRender, set: setRemoteRender }, true);
      const notesColour = (b_hasNotes) ? 'colour colour background-6 red' : 'colour lightGrey';
      const isChecked = selectedRows[row.equipmentId] || false; 

      return {
        rowSelected: { value: <UILIB.Checkbox name="rowSelected" checked={!!isChecked} onChange={(event) => rowSelected(event, row.equipmentId, setSelectedRows)} />, raw: row.equipmentId },
        SerialNumber: { value: row.SerialNumber, raw: row.SerialNumber },
        description: { value: row.description, raw: row.description },
        customerName: { value: row.customerName, raw: row.customerName },
        reason: { value: row.reason, raw: row.reason },
        createdDate: { value: formatDateTime(row.createdDateConvert, "DD/MM/YYYY"), raw: row.createdDate },
        createdBy: { value: row.createdBy, raw: row.createdBy },
        modifiedDate: { value: formatDateTime(row.modifiedDateConvert, "DD/MM/YYYY"), raw: row.modifiedDate },
        modifiedBy: { value: row.modifiedBy ? row.modifiedBy : 'N/A', raw: row.modifiedBy },
        note: { value: tableContainer(fn_notes, 'icon-pencil', 'View Notes', notesColour, true), raw: b_hasNotes }
      };
    });
    return tableData;
  }

  const getTableData = async (query, limit, offset, orderBy, orderDir, cancelToken, source) => {

    try {
      const expandArray = (source === 'primary') ? ['notes'] : [];
      const queryLocal = (query !== null) ? query : '';
      const pagingLocal = (limit !== null && offset !== null) ? `&$limit=${limit}&$offset=${offset}` : '';
      const orderLocal = (orderBy !== null && orderDir !== null) ? `&$order=${orderBy}&$direction=${orderDir}` : '';

      entityData.current = await Axios.get(`/entities/mif_exceptions/getSummary/?&$filter=${queryLocal}&$expand=${expandArray.map(x => x).join(' and ')}${pagingLocal}${orderLocal}`, { cancelToken: cancelToken.token }).then(api => api.data);

      if(!entityData.current || !entityData.current.result.length) return false;

      return { tableData: await constructTable(entityData.current.result), nonePaged: entityData.current.nonePaged };
    }
    catch(e) {
      console.log(`Exception: ${e}`);
    }
  }

  return (
    
    <Fragment>
        {showDeleteDialogue.show && <UILIB.MessageBox 
          header={'Delete Records'} 
          loading={deleting}
          text={`Click OK to DELETE the selected ${(showDeleteDialogue.count) > 1 ? 'Records' : 'Record'}`} 
          onConfirm={async () => await handleDelete(showDeleteDialogue.exceptionIds)} 
          onCancel={() => setShowDeleteDialogue({ ...showDeleteDialogue, show: false })} />}
      <UILIB.Paper className="width-100">
      <div class="flex-container row mar-b10">
        <div class="flex-item flex-grow-1 end wrap">
          <UILIB.Button 
            className="button height-100 mar-l5 small red" 
            value='Delete exception' 
            onClick={async () => await handleDeleteExceptionClicked()}
            disabled={!Object.values(selectedRows).some(value => value) ||
            !Object.keys(selectedRows).some(id => selectedRows[id] && checkRow(id, entityData, 'equipmentId'))}
          />
          </div>
        </div>
        <UILIB.TableNew
          className="small"
          overflowX="auto"
          overflowY="hidden"
          header={headerData}
          localQuery={() => constructTable((entityData.current && entityData.current.result) ? entityData.current.result : [])}
          localRender={[selectedRows, localRender]}
          remoteQuery={getTableData}
          remoteRender={[remoteRender]}
          defaultSettings={tablePageDefaults} />
      </UILIB.Paper>
    </Fragment>
  );
}
